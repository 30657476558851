import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { FullShow } from "../../models/Shows";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { Cast } from "../../models/Cast";

import { Form, Row } from "react-bootstrap";

import { TableSkeleton } from "../atoms/Skeletons";
import SubmitButton from "../atoms/SubmitButton";
import LogScoresEpisodeSelect from "../atoms/LogScoresEpisodeSelect";
import PageTemplate from "../templates/PageTemplate";
import SearchSelect from "../atoms/SearchSelect";
import Switch from "../atoms/Switch";
import AreYouSureModal from "../atoms/AreYouSureModal";

const validationSchema = yup.object({
    castId: yup.number().nullable().moreThan(-1, "Invalid Cast Value"),
    scoreId: yup.number().nullable().moreThan(-1, "Invalid Score Value"),
    eliminated: yup.boolean().nullable(),
});

interface ParamTypes {
    showId: string;
    episodeParam?: string;
}

const AdminLogLiveScores: React.FC = () => {
    const { showId, episodeParam } = useParams<ParamTypes>();

    const [show, setShow] = useState<FullShow>();
    const [scores, setScores] = useState<
        { value: number; label: string; type: string }[]
    >([]);
    const [players, setPlayers] = useState<
        { value: number; label: string; eliminated: boolean }[]
    >([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showConvertLiveScores, setShowConvertLiveScores] =
        useState<boolean>(false);
    const episode = episodeParam ? episodeParam : 0;

    //could send through type to see if we need to grab custom or regular
    const [submitting, setSubmitting] = useState<boolean>(false);

    const playerRequest = useApi(apiRoutes.GET_CAST_BY_SHOW(showId), {
        responseKey: "cast",
        onSuccess: (response: Cast[]) => {
            if (response.length > 0) {
                const availablePlayers = response.filter((player) => {
                    if (player.episode_eliminated === null) {
                        return true;
                    } else {
                        return (
                            parseInt(player.episode_eliminated.toString()) >=
                            parseInt(episode.toString())
                        );
                    }
                });
                const avPlayers = availablePlayers.map((player) => {
                    return {
                        value: player.id,
                        label: player.name,
                        eliminated: player.eliminated,
                    };
                });
                setPlayers([
                    {
                        value: 0,
                        label: "Everyone (non eliminated)",
                        eliminated: false,
                    },
                    ...avPlayers,
                ]);
            }
        },
    });
    const showRequest = useApi(apiRoutes.GET_SHOW(showId), {
        responseKey: "show",
        onSuccess: (response: FullShow) => {
            const scores = response.scoring.map((score) => {
                return {
                    value: score.id,
                    label: score.description,
                    type: "score",
                };
            });
            const questions = response.questions.map((question) => {
                return {
                    value: question.id,
                    label: question.description,
                    type: "question",
                };
            });
            setScores([...scores, ...questions]);
            setShow(response);
            setLoading(false);
        },
        onFailure: () => {
            setLoading(false);
        },
    });
    const saveLiveScoringRequest = useApi(
        apiRoutes.SAVE_LIVE_SCORES(showId.toString(), episode.toString()),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                setSubmitting(false);
                toast.success(message);
                playerRequest.request();
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );
    const convertLiveScoring = useApi(
        apiRoutes.CONVERT_LIVE_SCORING(showId.toString(), episode.toString()),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                setSubmitting(false);
                toast.success(message);
                setShowConvertLiveScores(false);
            },
            onFailure: () => {
                setLoading(false);
            },
        }
    );

    useEffect(() => {
        playerRequest.request();
        showRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        castId: 0,
        scoreId: 0,
        type: "score",
        eliminated: false,
    };

    return (
        <PageTemplate
            header="Log Live Scores"
            headerSecondary={show && show.show}
            loading={loading}
            skeleton={
                <>
                    <TableSkeleton />
                    <TableSkeleton />
                </>
            }
        >
            {show && (
                <>
                    <LogScoresEpisodeSelect
                        live
                        show={show}
                        episode={episode}
                    />
                    {parseInt(episode.toString()) > 0 && players.length > 0 && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                setSubmitting(true);
                                saveLiveScoringRequest.request({
                                    cast_id: values.castId,
                                    score_id: values.scoreId,
                                    eliminated: values.eliminated,
                                    type: values.type,
                                });
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleSubmit,
                                setFieldValue,
                                isValid,
                            }) => {
                                return (
                                    <Form
                                        onSubmit={(e) => {
                                            if (!isValid) {
                                                toast.error(
                                                    "Please fix the errors"
                                                );
                                            } else {
                                                handleSubmit(e);
                                            }
                                        }}
                                    >
                                        <SearchSelect
                                            placeholder="Choose Player..."
                                            options={players}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "castId",
                                                    value.value
                                                );
                                                setFieldValue(
                                                    "eliminated",
                                                    value.eliminated
                                                );
                                            }}
                                        />
                                        <SearchSelect
                                            placeholder="Choose Score..."
                                            options={scores}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    "scoreId",
                                                    value.value
                                                );
                                                setFieldValue(
                                                    "type",
                                                    value.type
                                                );
                                            }}
                                        />
                                        <Switch
                                            name="eliminated"
                                            label="Eliminated?"
                                        />
                                        <Row>
                                            <SubmitButton
                                                className="mr-3 ml-3"
                                                title="Submit Score"
                                                submitting={submitting}
                                            />
                                            {/* <div
                                                style={{
                                                    flex: 1,
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    justifyContent: "flex-end",
                                                    marginRight: 15,
                                                }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        setShowConvertLiveScores(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Convert Live Scoring
                                                </Button>
                                            </div> */}
                                        </Row>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </>
            )}
            {show && episode && parseInt(episode) > 0 ? (
                <AreYouSureModal
                    submitting={submitting}
                    showModal={showConvertLiveScores}
                    setShowModal={setShowConvertLiveScores}
                    body={`Are you sure you want to convert live scoring for episode ${
                        show.type === "week" ? "Week" : "Episode"
                    } ${episode} for ${show.show}?`}
                    onSuccess={() => convertLiveScoring.request()}
                />
            ) : (
                <></>
            )}
        </PageTemplate>
    );
};

export default AdminLogLiveScores;
