import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Moment from "react-moment";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { RootState } from "../../store";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { UserState } from "../../store/user/types";
import { logout, updateUser } from "../../store/user/actions";
import { Subscription } from "../../models/Subscriptions";
import { updateSubscription } from "../../store/subscription/actions";
import { SubscriptionState } from "../../store/subscription/types";

import Input from "../atoms/Input";
import { ArticleTextSkeleton } from "../atoms/Skeletons";
import Checkbox from "../atoms/Checkbox";
import Switch from "../atoms/Switch";
import SubmitButton from "../atoms/SubmitButton";
import AreYouSureModal from "../atoms/AreYouSureModal";
import PageTemplate from "../templates/PageTemplate";
import { UserCode } from "./styles/UserPreferencesForm.styled";
import AlertModal from "../atoms/AlertModal";
import { CircularProgress } from "@mui/material";
import {
    hasPremiumSub,
    hasStandardSub,
} from "../../helpers/checkSubscriptions";

const validationSchema = yup.object({
    darkMode: yup.mixed(),
    email: yup
        .string()
        .email("Email must be a valid email")
        .required("Email is required"),
    unsubscribeArticles: yup.mixed(),
    unsubscribeShows: yup.mixed(),
    unsubscribeScores: yup.mixed(),
    unsubscribeLineups: yup.mixed(),
});

const UserPreferencesForm: React.FC = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.user);
    const [subscription, setSubscription] = useState<Subscription>();
    const [cancellingSubscription, setCancellingSubscription] =
        useState<boolean>(false);
    const [subscriptionType, setSubscriptionType] = useState<string>("default");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const deleteAccount = useApi(apiRoutes.DELETE_ACCOUNT(), {
        onSuccess: () => {
            toast.success("Account Deleted!");
            setDeleting(false);
            dispatch(logout());
        },
        onFailure: () => {
            setDeleting(false);
        },
    });
    const saveRequest = useApi(apiRoutes.SAVE_PREFERENCES(), {
        onSuccess: (response: {
            message: string;
            user: UserState;
            subscription: SubscriptionState;
        }) => {
            setSubmitting(false);
            toast.success(response.message);
            dispatch(updateUser(response.user));
            dispatch(updateSubscription(response.subscription));
        },
        onFailure: () => setSubmitting(false),
    });
    const getSubscriptionRequest = useApi(apiRoutes.GET_SUBSCRIPTION(), {
        responseKey: "subscription",
        onSuccess: (response: Subscription) => {
            setSubscriptionType(response.name);
            setSubscription(response);
        },
    });
    const cancelSubscriptionRequest = useApi(
        apiRoutes.CANCEL_SUBSCRIPTION(subscriptionType),
        {
            responseKey: "message",
            onSuccess: (message: string) => {
                toast.success(message);
                setCancellingSubscription(false);
                getSubscriptionRequest.request();
            },
            onFailure: (message: string) => {
                setCancellingSubscription(false);
            },
        }
    );

    const getSubscriptionName = (name: string): string => {
        if (hasStandardSub(name)) {
            return "Standard";
        } else if (hasPremiumSub(name)) {
            return "Premium";
        } else {
            return name;
        }
    };

    useEffect(() => {
        getSubscriptionRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        darkMode: user.dark_mode,
        email: user.email,
        unsubscribeArticles: user.unsubscribe_article,
        unsubscribeShows: user.unsubscribe_shows,
        unsubscribeScores: user.unsubscribe_scores,
        unsubscribeLineups: user.unsubscribe_lineups,
    };

    return (
        <PageTemplate
            header="User Preferences"
            headerSecondary="RealTVFantasy"
            skeleton={
                <>
                    <ArticleTextSkeleton />
                    <ArticleTextSkeleton />
                </>
            }
        >
            {user && user.isLoggedIn && (
                <>
                    <Form.Group>
                        <Form.Label className="col-form-label">
                            User Code: {user.user_code}
                        </Form.Label>
                        <UserCode>
                            This is used so that someone can add you as a
                            co-owner of a team in a league.
                        </UserCode>
                    </Form.Group>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            setSubmitting(true);
                            saveRequest.request({
                                email: values.email,
                                dark_mode: values.darkMode,
                                unsubscribe_article: values.unsubscribeArticles,
                                unsubscribe_scores: values.unsubscribeScores,
                                unsubscribe_shows: values.unsubscribeShows,
                                unsubscribe_lineups: values.unsubscribeLineups,
                            });
                        }}
                    >
                        {({ handleSubmit, isValid }) => (
                            <Form
                                onSubmit={(e) => {
                                    if (!isValid) {
                                        toast.error("Please fix the errors");
                                    }
                                    handleSubmit(e);
                                }}
                            >
                                <Switch name="darkMode" label="Dark Mode" />
                                <Input
                                    name="email"
                                    label="Email:"
                                    placeholder="Email"
                                />
                                <Form.Group>
                                    <Form.Label>
                                        Unsubscribe From (Emails):
                                    </Form.Label>
                                    <Checkbox
                                        label="New Articles"
                                        name="unsubscribeArticles"
                                    />
                                    <Checkbox
                                        label="Scores Logged"
                                        name="unsubscribeScores"
                                    />
                                    <Checkbox
                                        label="New Shows"
                                        name="unsubscribeShows"
                                    />
                                    <Checkbox
                                        label="Set Lineups"
                                        name="unsubscribeLineups"
                                    />
                                </Form.Group>
                                {subscription && (
                                    <Form.Group>
                                        <Form.Label className="mr-1">
                                            Subscription:
                                        </Form.Label>
                                        {
                                            <b>
                                                {getSubscriptionName(
                                                    subscription.name
                                                )}
                                            </b>
                                        }
                                        {subscription.ends_at && (
                                            <span className="ml-1">
                                                - Cancels on
                                                <Moment
                                                    className="ml-1"
                                                    format="MMMM D, YYYY"
                                                >
                                                    {subscription.ends_at}
                                                </Moment>
                                            </span>
                                        )}
                                        {!subscription.ends_at && (
                                            <Button
                                                disabled={
                                                    cancellingSubscription
                                                }
                                                className="ml-2"
                                                variant="danger"
                                                onClick={() => {
                                                    if (
                                                        subscription.stripe_id
                                                    ) {
                                                        setShowModal(true);
                                                    } else {
                                                        setShowAlert(true);
                                                    }
                                                }}
                                            >
                                                {cancellingSubscription
                                                    ? "Cancelling..."
                                                    : "Cancel Subscription"}
                                            </Button>
                                        )}
                                    </Form.Group>
                                )}
                                <SubmitButton
                                    title="Update Preferences"
                                    submitText="Updating"
                                    submitting={submitting}
                                />
                                <Button
                                    type="button"
                                    className="ml-3"
                                    variant="danger"
                                    disabled={deleting}
                                    onClick={() => setShowDeleteModal(true)}
                                >
                                    {deleting ? (
                                        <>
                                            Deleting{" "}
                                            <CircularProgress
                                                size={12}
                                                color="inherit"
                                            />
                                        </>
                                    ) : (
                                        <>Delete Account</>
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            <AlertModal
                showModal={showAlert}
                setShowModal={setShowAlert}
                title="Cancel Subscription"
                body={
                    <p>
                        You currently have{" "}
                        {subscription?.platform === "ios"
                            ? "an Apple"
                            : "a Google"}{" "}
                        subscription. If you would like to cancel, please follow
                        the instructions{" "}
                        {subscription?.platform === "ios" ? (
                            <a
                                href="https://support.apple.com/en-us/HT202039"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here.
                            </a>
                        ) : (
                            <a
                                href="https://support.google.com/googleplay/answer/7018481"
                                target="_blank"
                                rel="noreferrer"
                            >
                                here.
                            </a>
                        )}{" "}
                        If you're still having trouble, contact us.
                    </p>
                }
            />
            <AreYouSureModal
                showModal={showModal}
                setShowModal={setShowModal}
                body={`Are you sure you want to cancel your subscription?`}
                onSuccess={() => {
                    setCancellingSubscription(true);
                    cancelSubscriptionRequest.request();
                    setShowModal(false);
                }}
            />
            <AreYouSureModal
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                body={`Are you sure you want to delete your account!? THIS CANNOT BE UNDONE. If you have an Apple or Google subscription with us, you will have to manually cancel those yourself.`}
                onSuccess={() => {
                    setDeleting(true);
                    deleteAccount.request();
                    setShowDeleteModal(false);
                }}
            />
        </PageTemplate>
    );
};

export default UserPreferencesForm;
