import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { League } from "../../models/Leagues";
import { Cast } from "../../models/Cast";

import Select from "../atoms/Select";
import SubmitButton from "../atoms/SubmitButton";
import { InputSkeleton } from "../atoms/Skeletons";
import { ModalStyled } from "../atoms";

interface AddPlayerProps {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    league: League;
    episode?: number;
    teamId: number;
    onSubmit: (playerId: number) => void;
    submitting?: boolean;
}

const validationSchema = yup.object({
    playerId: yup
        .number()
        .moreThan(0, "Please Select Player")
        .required("Please Select Player"),
});

const AddPlayerModal: React.FC<AddPlayerProps> = ({
    visible,
    setVisible,
    episode,
    league,
    teamId,
    onSubmit,
    submitting,
}) => {
    const [players, setPlayers] = useState<Cast[]>();
    const [error, setError] = useState<string>("");

    const listRequest = useApi(apiRoutes.GET_PLAYER_LIST(teamId.toString()), {
        onSuccess: (response: { list: Cast[]; error?: string }) => {
            if (response.error) {
                setError(response.error);
            } else {
                if (response.list.length > 0) {
                    setPlayers(response.list);
                    setError("");
                } else {
                    setError("No Cast Available");
                }
            }
        },
    });

    useEffect(() => {
        if (visible) {
            if (episode) {
                listRequest.request({ episode });
            } else {
                listRequest.request();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, episode]);

    const initialValues = {
        playerId: 0,
    };

    return (
        <ModalStyled
            show={visible}
            onHide={() => setVisible(false)}
            size="lg"
            className="text-center"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Player to{" "}
                    {league.draft_type === "Full Draft" ||
                    (league.draft_type === "Team" && !episode)
                        ? "Team"
                        : `Episode ${episode ? episode : ""}`}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        onSubmit(values.playerId);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            {error.length > 0 && (
                                <Alert variant="danger">{error}</Alert>
                            )}
                            {listRequest.loading && <InputSkeleton />}
                            {!listRequest.loading &&
                                players &&
                                error.length === 0 && (
                                    <>
                                        <Select
                                            name="playerId"
                                            defaultKey={0}
                                            defaultValue="Choose Player"
                                            selectKey="id"
                                            selectValue="name"
                                            items={players}
                                        />
                                        <SubmitButton
                                            submitting={submitting}
                                            submitText="Adding"
                                            title="Add Player"
                                        />{" "}
                                    </>
                                )}
                            <Button
                                variant="light"
                                onClick={() => setVisible(false)}
                            >
                                Cancel
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </ModalStyled>
    );
};

export default AddPlayerModal;
