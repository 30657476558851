import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import * as yup from "yup";

import useApi from "../../hooks/useApi";
import { UserModel } from "../../models/User";
import apiRoutes from "../../services/apiRoutes";

import Form from "react-bootstrap/Form";

import PageTemplate from "../templates/PageTemplate";
import Checkbox from "../atoms/Checkbox";
import SubmitButton from "../atoms/SubmitButton";

const validationSchema = yup.object({
    unsubscribeArticles: yup.mixed(),
    unsubscribeShows: yup.mixed(),
    unsubscribeScores: yup.mixed(),
    unsubscribeLineups: yup.mixed(),
});

interface ParamTypes {
    email: string;
}

const Unsubscribe: React.FC = () => {
    const { email } = useParams<ParamTypes>();
    const [user, setUser] = useState<UserModel>();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [initialValues, setInitialValues] = useState({
        unsubscribeArticles: false,
        unsubscribeShows: false,
        unsubscribeScores: false,
        unsubscribeLineups: false,
    });

    const getUserRequest = useApi(apiRoutes.GET_USER_BY_EMAIL(email), {
        responseKey: "user",
        onSuccess: (response: UserModel) => {
            setUser(response);
            setInitialValues({
                unsubscribeArticles: response.unsubscribe_article,
                unsubscribeLineups: response.unsubscribe_lineups,
                unsubscribeScores: response.unsubscribe_scores,
                unsubscribeShows: response.unsubscribe_shows,
            });
        },
    });
    const saveUnsubscribeRequest = useApi(
        apiRoutes.SAVE_UNSUBSCRIBE(user ? user.id.toString() : "0"),
        {
            responseKey: "message",
            onSuccess: (response: string) => {
                toast.success(response);
                setSubmitting(false);
            },
            onFailure: (response: string) => {
                setSubmitting(false);
            },
        }
    );

    useEffect(() => {
        getUserRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageTemplate header="User Preferences">
            {user && !getUserRequest.loading && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        saveUnsubscribeRequest.request({
                            unsubscribe_article: values.unsubscribeArticles,
                            unsubscribe_scores: values.unsubscribeScores,
                            unsubscribe_shows: values.unsubscribeShows,
                            unsubscribe_lineups: values.unsubscribeLineups,
                        });
                    }}
                >
                    {({ handleSubmit, isValid }) => (
                        <Form
                            onSubmit={(e) => {
                                if (!isValid) {
                                    toast.error("Please fix the errors");
                                }
                                handleSubmit(e);
                            }}
                        >
                            <Form.Group>
                                <Form.Label>Unsubscribe From:</Form.Label>
                                <Checkbox
                                    label="New Articles"
                                    name="unsubscribeArticles"
                                />
                                <Checkbox
                                    label="Scores Logged"
                                    name="unsubscribeScores"
                                />
                                <Checkbox
                                    label="New Shows"
                                    name="unsubscribeShows"
                                />
                                <Checkbox
                                    label="Set Lineups"
                                    name="unsubscribeLineups"
                                />
                            </Form.Group>
                            <SubmitButton
                                title="Update Preferences"
                                submitText="Updating"
                                submitting={submitting}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </PageTemplate>
    );
};

export default Unsubscribe;
