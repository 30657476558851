import React from "react";
import { useSelector } from "react-redux";

import { League } from "../../models/Leagues";
import { LeagueUser, Team } from "../../models/LeagueUsers";
import { Show } from "../../models/Shows";
import { RootState } from "../../store";

import Table from "../atoms/Table";
import PlayerRow from "./PlayerRow";
import PlayerTableHeader from "./PlayerTableHeader";

interface CurrentTeamProps {
    league: League;
    show: Show;
    currentTeam: Team[];
    leagueUser: LeagueUser;
    categories: string[];
    commissioner: boolean;
    setSelectedEpisode: (episode: number) => void;
    setSelectedPlayer: (player: Team) => void;
    setShowDeleteModal: (show: boolean) => void;
}

const CurrentTeam: React.FC<CurrentTeamProps> = ({
    league,
    show,
    currentTeam,
    leagueUser,
    categories,
    commissioner,
    setSelectedEpisode,
    setSelectedPlayer,
    setShowDeleteModal,
}) => {
    const user = useSelector((state: RootState) => state.user);

    const teamHidden = () => {
        const coOwners =
            leagueUser.co_owners !== null
                ? leagueUser.co_owners.split(",")
                : [];
        if (show.current_episode === 1 && !show.locked && user) {
            if (coOwners.some((item) => user.id.toString() === item)) {
                return false;
            } else if (leagueUser.user_id.toString() === user.id.toString()) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return (
        <div>
            <h3 className="mb-4 d-flex justify-content-center align-items-center">
                Current Team
            </h3>
            <Table>
                <PlayerTableHeader categories={categories} />
                <tbody>
                    {currentTeam &&
                        teamHidden() === false &&
                        currentTeam.length > 0 &&
                        currentTeam.map((player: Team, i: number) => {
                            return (
                                <PlayerRow
                                    key={i}
                                    currentEpisode={show.current_episode}
                                    player={player}
                                    league={league}
                                    commissioner={commissioner}
                                    onDeleteClick={(e: any) => {
                                        e.stopPropagation();
                                        setSelectedEpisode(0);
                                        setSelectedPlayer(player);
                                        setShowDeleteModal(true);
                                    }}
                                />
                            );
                        })}
                    {teamHidden() === true && (
                        <tr>
                            <td
                                className="text-center p-4"
                                colSpan={categories.length + 2}
                            >
                                Current Team Hidden Until First{" "}
                                {show.type === "week" ? "Week" : "Episode"}{" "}
                                Locks
                            </td>
                        </tr>
                    )}
                    {currentTeam.length <= 0 && teamHidden() === false && (
                        <tr>
                            <td
                                className="text-center p-4"
                                colSpan={categories.length + 2}
                            >
                                No Players Found
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <h3 className="mb-4 d-flex justify-content-center align-items-center">
                Weekly Scoring
            </h3>
        </div>
    );
};

export default CurrentTeam;
