import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Avatar from "@mui/material/Avatar";

import { RootState } from "../../store";
import { logout, updateUser } from "../../store/user/actions";
import useApi from "../../hooks/useApi";
import apiRoutes from "../../services/apiRoutes";
import { ShowList } from "../../models/Shows";
import { setShows } from "../../store/shows/actions";
import { setNotifications } from "../../store/notifications/actions";
import { UserModel } from "../../models/User";
import { SubscriptionState } from "../../store/subscription/types";
import { updateSubscription } from "../../store/subscription/actions";

import NotificationList from "./NotificationList";
import { NotificationList as NoteList } from "../../models/Notifications";
import { InputSkeleton } from "../atoms/Skeletons";
import {
    AdminDropdown,
    NavbarContainer,
    NavUser,
    NotificationBadge,
    SiteImageWrapper,
} from "./styles/Navigation.styled";
import { TimezoneOffset } from "../../models/TimezoneOffset";
import { updateTimezoneOffsets } from "../../store/timezoneOffsets/actions";
import { imageUrl } from "../../config";

interface NavigationProps {
    setShowLogin: (showLogin: boolean) => void;
}

const Navigation: React.FC<NavigationProps> = ({ setShowLogin }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const notifications = useSelector(
        (state: RootState) => state.notifications
    );

    const getShows = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        responseKey: "shows",
        onSuccess: (response: ShowList[]) => dispatch(setShows(response)),
    });
    const getNotifications = useApi(apiRoutes.GET_NOTIFICATIONS(), {
        responseKey: "notifications",
        onSuccess: (response: NoteList) => {
            dispatch(setNotifications(response));
        },
    });
    const getUser = useApi(apiRoutes.GET_USER(), {
        onSuccess: (response: {
            user: UserModel;
            subscription: SubscriptionState;
        }) => {
            dispatch(updateUser({ ...response.user, isLoggedIn: true }));
            dispatch(updateSubscription(response.subscription));
        },
    });
    const getTimezoneOffsets = useApi(apiRoutes.OFFSET(), {
        onSuccess: (response: TimezoneOffset) => {
            dispatch(updateTimezoneOffsets(response));
        },
        responseKey: "offsets",
    });

    useEffect(() => {
        getShows.request();
        getTimezoneOffsets.request();
        if (user.isLoggedIn) {
            getUser.request();
            getNotifications.request();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <NavbarContainer
            style={{ zIndex: 2000 }}
            fixed="top"
            variant="dark"
            expand="lg"
        >
            <SiteImageWrapper href="/">
                <Avatar
                    src="/images/RealTVFantasy_icon.png"
                    className="d-inline-block align-top mr-2"
                />{" "}
                RealTVFantasy
            </SiteImageWrapper>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
                className="justify-content-end"
                id="basic-navbar-nav"
            >
                <Nav className="align-items-center">
                    <Nav.Link href="/overview">How It Works</Nav.Link>
                    <AdminDropdown
                        title="Shows"
                        id="basic-nav-dropdown"
                        alignRight={true}
                    >
                        {!getShows.loading &&
                            getShows.data &&
                            getShows.data.map((show: ShowList) => {
                                return (
                                    <NavDropdown.Item
                                        key={show.id}
                                        href={`/shows/view/${show.slug}`}
                                    >
                                        {show.show}
                                    </NavDropdown.Item>
                                );
                            })}
                        {getShows.loading && (
                            <div className="ml-2 mr-2">
                                <InputSkeleton />
                                <InputSkeleton />
                            </div>
                        )}
                        {user.isLoggedIn && (
                            <NavDropdown.Item href="/build">
                                <FaPlus /> Create Your Own
                            </NavDropdown.Item>
                        )}
                    </AdminDropdown>
                    {user.isLoggedIn && (
                        <Nav.Link href={`/myleagues`}>My Leagues</Nav.Link>
                    )}
                    {user.admin && (
                        <AdminDropdown
                            title="Admin"
                            id="basic-nav-dropdown"
                            alignRight={true}
                        >
                            <NavDropdown.Item href={`/admin/scores`}>
                                Manage Scores
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`/admin/liveScores`}>
                                Manage Live Scores
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`/admin/articles`}>
                                Manage Articles
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`/admin/shows`}>
                                Manage Shows
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`/admin/addinstabio`}>
                                Add Instagram Bio
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                href={`/admin/sendPushNotification`}
                            >
                                Send Push Notification
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`/admin/deeplinking`}>
                                Deep Linking
                            </NavDropdown.Item>
                        </AdminDropdown>
                    )}
                    <Nav.Link href="/news">News</Nav.Link>
                    {user.isLoggedIn && (
                        <NotificationBadge
                            badgeContent={
                                notifications.length > 0
                                    ? notifications.length
                                    : null
                            }
                            color="error"
                        >
                            <NavUser
                                alignRight={true}
                                title={
                                    <>
                                        <Avatar
                                            className="mr-2"
                                            src={
                                                user.profile_pic &&
                                                user.profile_pic.includes(
                                                    "https"
                                                )
                                                    ? user.profile_pic
                                                    : `${imageUrl}${user.profile_pic}`
                                            }
                                        />
                                        {` ${user.first_name}`}
                                    </>
                                }
                                id="basic-nav-dropdown nav-image"
                            >
                                <NavDropdown.Item href="/subscribe">
                                    Subscribe
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/profile">
                                    My Profile
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/preferences">
                                    Preferences
                                </NavDropdown.Item>
                                <NotificationList />
                                <NavDropdown.Item
                                    href="#"
                                    onClick={() => dispatch(logout())}
                                >
                                    Logout
                                </NavDropdown.Item>
                            </NavUser>
                        </NotificationBadge>
                    )}
                    {!user.isLoggedIn && (
                        <Nav.Link href="#" onClick={() => setShowLogin(true)}>
                            Login
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </NavbarContainer>
    );
};

export default Navigation;
