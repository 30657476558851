import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";

import useApi from "../../hooks/useApi";
import { Subscription } from "../../models/Subscriptions";
import apiRoutes from "../../services/apiRoutes";
import { updateSubscription } from "../../store/subscription/actions";
import { SubscriptionState } from "../../store/subscription/types";
import { RootState } from "../../store";
import useQuery from "../../hooks/useQuery";
import { UserModel } from "../../models/User";
import { login, updateUser } from "../../store/user/actions";

import PriceCard from "../atoms/PriceCard";
import StripeModal from "../molecules/StripeModal";
import PageTemplate from "../templates/PageTemplate";
import AlertModal from "../atoms/AlertModal";
import { SubTabsWrapper } from "../atoms";
import moment from "moment";

const Subscribe: React.FC = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const userToken = query.get("token") ? query.get("token") : "";
    const template = query.get("template") ? query.get("template") : "";
    const user = useSelector((state: RootState) => state.user);
    const [showStripeModal, setShowStripeModal] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [subscription, setSubscription] = useState<Subscription>();
    const [title, setTitle] = useState<string>("Standard Subscription");
    const [amount, setAmount] = useState<number>(1);

    const getSubscriptionRequest = useApi(apiRoutes.GET_SUBSCRIPTION(), {
        responseKey: "subscription",
        onSuccess: (response: Subscription) => {
            setSubscription(response);
            dispatch(updateSubscription(response));
        },
    });
    const getUserByTokenRequest = useApi(apiRoutes.GET_USER_BY_TOKEN(), {
        onSuccess: (response: {
            user: UserModel;
            subscription: Subscription;
        }) => {
            setSubscription(response.subscription);
            dispatch(login({ ...response.user, isLoggedIn: true }));
            dispatch(updateSubscription(response.subscription));
        },
    });
    const createSubscriptionRequest = useApi(apiRoutes.CREATE_SUBSCRIPTION(), {
        onSuccess: (response: {
            message: string;
            subscription: SubscriptionState;
            user: UserModel;
        }) => {
            setSubmitting(false);
            if (template === "blank") {
                toast.success(
                    "Thank you for subscribing! To activate your subscription, navigate to your MyLeagues tab and refresh. Or you can close the app and reopen it"
                );
            } else {
                toast.success(response.message);
            }
            dispatch(updateSubscription(response.subscription));
            dispatch(updateUser({ ...response.user, isLoggedIn: true }));
            setSubscription(response.subscription);
            setShowStripeModal(false);
        },
        onFailure: (error: string) => {
            setSubmitting(false);
        },
    });

    useEffect(() => {
        if (user.isLoggedIn) {
            getSubscriptionRequest.request();
        } else {
            if (userToken.length > 0) {
                getUserByTokenRequest.request({ token: userToken });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const elements = useElements();
    const stripe = useStripe();

    const handleSubmit = async (email: string, amount: number) => {
        setSubmitting(true);

        const response = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
            },
        });

        if (response.paymentMethod) {
            let type: string = "Standard_v2";
            if (title === "Premium Subscription") {
                type = "Premium";
            } else if (title === "Standard Subscription") {
                type = "Standard_v2";
            } else if (title === "Premium Subscription Annual") {
                type = "Premium Annual";
            } else if (title === "Standard Subscription Annual") {
                type = "Standard Annual";
            }

            if (
                (type === "Premium" || type === "Premium Annual") &&
                !user.free_trial_used
            ) {
                createSubscriptionRequest.request({
                    stripeToken: response.paymentMethod.id,
                    type,
                    freeTrial: true,
                });
            } else {
                createSubscriptionRequest.request({
                    stripeToken: response.paymentMethod.id,
                    type,
                });
            }
        } else {
            setSubmitting(false);
            toast.error(
                response.error.message ?? "There was an issue subscribing"
            );
        }
    };

    return (
        <PageTemplate header="Subscribe" bareContent>
            <>
                {subscription && subscription !== undefined && (
                    <Alert variant="warning">
                        To cancel your subscription, go to your{" "}
                        <a href="/preferences" target="_blank">
                            preferences
                        </a>
                    </Alert>
                )}
                <SubTabsWrapper className="col-md-12">
                    <Tabs
                        defaultActiveKey="annual"
                        className="col-md-12 pl-0 pr-0 justify-content-center"
                    >
                        <Tab
                            tabClassName="text-center m-2"
                            eventKey="monthly"
                            title="Monthly"
                        >
                            <Row className="justify-content-center">
                                <PriceCard
                                    disabled={
                                        user.isLoggedIn === false ||
                                        (subscription &&
                                            subscription !== undefined &&
                                            (subscription.name ===
                                                "Standard_v2" ||
                                                subscription.name ===
                                                    "standardSubscription" ||
                                                subscription.name ===
                                                    "standard_subscription" ||
                                                subscription.name ===
                                                    "standard_subscription:p1m"))
                                    }
                                    title="Standard"
                                    price={1}
                                    subscription
                                    listGroup={[
                                        "Ad-free experience",
                                        "No ads on web, mobile, or the app",
                                    ]}
                                    buttonText={
                                        subscription &&
                                        subscription !== undefined &&
                                        (subscription.name === "Standard_v2" ||
                                            subscription.name ===
                                                "standardSubscription" ||
                                            subscription.name ===
                                                "standard_subscription" ||
                                            subscription.name ===
                                                "standard_subscription:p1m")
                                            ? "Subscribed!"
                                            : user.isLoggedIn === false
                                            ? "Please Login to Subscribe"
                                            : subscription &&
                                              subscription !== undefined
                                            ? "Change Subscription"
                                            : "Subscribe"
                                    }
                                    onButtonPress={() => {
                                        if (
                                            !subscription ||
                                            (subscription &&
                                                subscription !== undefined &&
                                                subscription.stripe_id)
                                        ) {
                                            setTitle("Standard Subscription");
                                            setAmount(1);
                                            setShowStripeModal(true);
                                        } else {
                                            setShowAlert(true);
                                        }
                                    }}
                                />
                                <PriceCard
                                    disabled={
                                        user.isLoggedIn === false ||
                                        (subscription &&
                                            subscription !== undefined &&
                                            (subscription.name === "Premium" ||
                                                subscription.name ===
                                                    "premiumSubscription" ||
                                                subscription.name ===
                                                    "premium_subscription" ||
                                                subscription.name ===
                                                    "premium_subscription:p2m"))
                                    }
                                    title="Premium"
                                    price={3}
                                    subscription
                                    listGroup={[
                                        "Ad-free experience",
                                        "Analytics",
                                        "Live Scoring",
                                        "Free Sponsored Leagues",
                                    ]}
                                    buttonText={
                                        subscription &&
                                        subscription !== undefined &&
                                        (subscription.name === "Premium" ||
                                            subscription.name ===
                                                "premiumSubscription" ||
                                            subscription.name ===
                                                "premium_subscription" ||
                                            subscription.name ===
                                                "premium_subscription:p2m")
                                            ? "Subscribed!"
                                            : user.isLoggedIn === false
                                            ? "Please Login to Subscribe"
                                            : subscription &&
                                              subscription !== undefined
                                            ? "Change Subscription"
                                            : user.free_trial_used
                                            ? "Subscribe"
                                            : "Try 1 month for free"
                                    }
                                    onButtonPress={() => {
                                        if (
                                            !subscription ||
                                            (subscription &&
                                                subscription !== undefined &&
                                                subscription.stripe_id)
                                        ) {
                                            setTitle("Premium Subscription");
                                            setAmount(3);
                                            setShowStripeModal(true);
                                        } else {
                                            setShowAlert(true);
                                        }
                                    }}
                                />
                            </Row>
                        </Tab>
                        <Tab
                            tabClassName="text-center m-2"
                            eventKey="annual"
                            title="Annually"
                        >
                            <Row className="justify-content-center">
                                <PriceCard
                                    badge="Save 30%"
                                    annual
                                    disabled={
                                        user.isLoggedIn === false ||
                                        (subscription &&
                                            subscription !== undefined &&
                                            (subscription.name ===
                                                "Standard Annual" ||
                                                subscription.name ===
                                                    "standardSubscriptionAnnual" ||
                                                subscription.name ===
                                                    "standard_subscription_annual" ||
                                                subscription.name ===
                                                    "standard_subscription_annual:p1a"))
                                    }
                                    title="Standard"
                                    price={8}
                                    subscription
                                    listGroup={[
                                        "Ad-free experience",
                                        "No ads on web, mobile, or the app",
                                    ]}
                                    buttonText={
                                        subscription &&
                                        subscription !== undefined &&
                                        (subscription.name ===
                                            "Standard Annual" ||
                                            subscription.name ===
                                                "standardSubscriptionAnnual" ||
                                            subscription.name ===
                                                "standard_subscription_annual" ||
                                            subscription.name ===
                                                "standard_subscription_annual:p1a")
                                            ? "Subscribed!"
                                            : user.isLoggedIn === false
                                            ? "Please Login to Subscribe"
                                            : subscription &&
                                              subscription !== undefined
                                            ? "Change Subscription"
                                            : "Subscribe"
                                    }
                                    onButtonPress={() => {
                                        if (
                                            !subscription ||
                                            (subscription &&
                                                subscription !== undefined &&
                                                subscription.stripe_id)
                                        ) {
                                            setTitle(
                                                "Standard Subscription Annual"
                                            );
                                            setAmount(8);
                                            setShowStripeModal(true);
                                        } else {
                                            setShowAlert(true);
                                        }
                                    }}
                                />
                                <PriceCard
                                    badge="Save 30%"
                                    annual
                                    disabled={
                                        user.isLoggedIn === false ||
                                        (subscription &&
                                            subscription !== undefined &&
                                            (subscription.name ===
                                                "Premium Annual" ||
                                                subscription.name ===
                                                    "premiumSubscriptionAnnual" ||
                                                subscription.name ===
                                                    "premium_subscription_annual" ||
                                                subscription.name ===
                                                    "premium_subscription_annual:p2a"))
                                    }
                                    title="Premium"
                                    price={25}
                                    subscription
                                    listGroup={[
                                        "Ad-free experience",
                                        "Analytics",
                                        "Live Scoring",
                                        "Free Sponsored Leagues",
                                    ]}
                                    buttonText={
                                        subscription &&
                                        subscription !== undefined &&
                                        (subscription.name ===
                                            "Premium Annual" ||
                                            subscription.name ===
                                                "premiumSubscriptionAnnual" ||
                                            subscription.name ===
                                                "premium_subscription_annual" ||
                                            subscription.name ===
                                                "premium_subscription_annual:p2a")
                                            ? "Subscribed!"
                                            : user.isLoggedIn === false
                                            ? "Please Login to Subscribe"
                                            : subscription &&
                                              subscription !== undefined
                                            ? "Change Subscription"
                                            : user.free_trial_used
                                            ? "Subscribe"
                                            : "Try 1 month for free"
                                    }
                                    onButtonPress={() => {
                                        if (
                                            !subscription ||
                                            (subscription &&
                                                subscription !== undefined &&
                                                subscription.stripe_id)
                                        ) {
                                            setTitle(
                                                "Premium Subscription Annual"
                                            );
                                            setAmount(25);
                                            setShowStripeModal(true);
                                        } else {
                                            setShowAlert(true);
                                        }
                                    }}
                                />
                            </Row>
                        </Tab>
                    </Tabs>
                </SubTabsWrapper>
                <AlertModal
                    showModal={showAlert}
                    setShowModal={setShowAlert}
                    title="Subscription"
                    body={
                        <p>
                            You currently have{" "}
                            {subscription?.platform === "ios"
                                ? "an Apple"
                                : "a Google"}{" "}
                            subscription. You will need to cancel or update this
                            on your phone before updating your subscription on
                            the web. Please leave up to 48 hours for your
                            subscription cancellation to go through before
                            attempting to update it. If you would still like to
                            cancel, please follow the instructions{" "}
                            {subscription?.platform === "ios" ? (
                                <a
                                    href="https://support.apple.com/en-us/HT202039"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    here.
                                </a>
                            ) : (
                                <a
                                    href="https://support.google.com/googleplay/answer/7018481"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    here.
                                </a>
                            )}{" "}
                            If you're still having trouble,{" "}
                            <a
                                href="https://realtvfantasy.com/contact"
                                target="_blank"
                                rel="noreferrer"
                            >
                                contact us
                            </a>
                            .
                        </p>
                    }
                />
                <StripeModal
                    showModal={showStripeModal}
                    setShowModal={setShowStripeModal}
                    title={title}
                    description={
                        title === "Premium Subscription" &&
                        !user.free_trial_used
                            ? `You will not be charged for the first month, but if the subscription is not canceled before ${moment()
                                  .add(30, "days")
                                  .format(
                                      "MMMM Do YYYY"
                                  )} you will be charged $3 a month until canceled.`
                            : title === "Premium Subscription Annual" &&
                              !user.free_trial_used
                            ? `You will not be charged for the first month, but if the subscription is not canceled before ${moment()
                                  .add(30, "days")
                                  .format(
                                      "MMMM Do YYYY"
                                  )} you will be charged $25 a year until canceled`
                            : ""
                    }
                    onSubmit={handleSubmit}
                    freeTrial={
                        (title === "Premium Subscription" ||
                            title === "Premium Subscription Annual") &&
                        !user.free_trial_used
                    }
                    amount={amount}
                    submitting={submitting}
                />
            </>
        </PageTemplate>
    );
};

export default Subscribe;
