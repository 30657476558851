import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import useApi from "../../hooks/useApi";
import { PublicLeague } from "../../models/Leagues";
import { FullShow } from "../../models/Shows";
import apiRoutes from "../../services/apiRoutes";

import { FormGroupWrapper, Spinner } from "../atoms";
import Table from "../atoms/Table";
import PageTemplate from "../templates/PageTemplate";
import useQuery from "../../hooks/useQuery";

const PublicLeagueSearch: React.FC = () => {
    const query = useQuery();
    const showId = query.get("showId") ? parseInt(query.get("showId")) : 0;
    const [search, setSearch] = useState<string>("");
    const [selectedShow, setSelectedShow] = useState<number>(showId ?? 0);
    const [activeShows, setActiveShows] = useState<FullShow[]>();
    const [draftType, setDraftType] = useState<string>("All");
    const [leagues, setLeagues] = useState<PublicLeague[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const activeShowsRequest = useApi(apiRoutes.GET_SHOWS_BY_TYPE("active"), {
        responseKey: "shows",
        onSuccess: (response: FullShow[]) => setActiveShows(response),
    });

    const publicShowsRequest = useApi(apiRoutes.SEARCH_PUBLIC_LEAGUES(), {
        onSuccess: (response: {
            leagues: PublicLeague[];
            officialLeagues: PublicLeague[];
            sponsoredLeagues: PublicLeague[];
            page: number;
            has_more: boolean;
            count: number;
        }) => {
            if (search.length > 0) {
                setTotal(response.count);
            } else {
                if (draftType === "All") {
                    setTotal(response.count + 3); // +2 bc of the official leagues
                } else if (
                    draftType === "Full Draft" ||
                    draftType === "Confidence Pool" ||
                    draftType === "Eliminated Confidence Pool"
                ) {
                    setTotal(response.count);
                } else {
                    setTotal(response.count + 1); // +1 bc of the official leagues
                }
            }
            const sponsored = response.leagues.filter(
                (league) => league.sponsored
            );
            const leagues = response.leagues.filter(
                (league) => !league.sponsored
            );

            setLeagues([...sponsored, ...response.officialLeagues, ...leagues]);
            setLoading(false);
        },
    });

    useEffect(() => {
        activeShowsRequest.request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedShow > 0) {
            setLoading(true);
            publicShowsRequest.request({
                show_id: selectedShow,
                value: search,
                draftType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShow]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(true);
            publicShowsRequest.request({
                show_id: selectedShow,
                value: search,
                draftType,
            });
        }, 500);
        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        setLoading(true);
        publicShowsRequest.request({
            show_id: selectedShow,
            value: search,
            draftType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftType]);

    const determineLeagueAction = (league: PublicLeague) => {
        if (league.active) {
            if (league.league_size) {
                if (league.league_size - league.players > 0) {
                    return (
                        <Button
                            href={`/myleagues/view/${league.id}${
                                league.id === "0" ||
                                league.id === 0 ||
                                league.id === "team" ||
                                league.id === "budget"
                                    ? `/${league.show_id}`
                                    : ""
                            }`}
                        >
                            View League
                        </Button>
                    );
                } else {
                    return <Button disabled>League Full</Button>;
                }
            } else {
                if (league.draft_type === "Full Draft" && league.drafted) {
                    return <Button disabled>Drafted</Button>;
                } else {
                    return (
                        <Button
                            href={`/myleagues/view/${league.id}${
                                league.id === "0" ||
                                league.id === 0 ||
                                league.id === "team" ||
                                league.id === "budget"
                                    ? `/${league.show_id}`
                                    : ""
                            }`}
                        >
                            View League
                        </Button>
                    );
                }
            }
        } else {
            return <Button disabled>League Completed</Button>;
        }
    };

    return (
        <PageTemplate header="Find Public League">
            <FormGroupWrapper>
                <Form.Control
                    as="select"
                    value={selectedShow}
                    onChange={(e) => setSelectedShow(parseInt(e.target.value))}
                >
                    <option value={0}>Choose Show</option>
                    {activeShows &&
                        activeShows.map((show: FullShow) => {
                            return (
                                <option key={show.id} value={show.id}>
                                    {show.show}
                                </option>
                            );
                        })}
                </Form.Control>
            </FormGroupWrapper>
            {selectedShow > 0 && (
                <>
                    <FormGroupWrapper>
                        <Form.Label>Search by League Name:</Form.Label>
                        <Form.Control
                            placeholder="Search..."
                            value={search}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(ev) => setSearch(ev.target.value)}
                        />
                    </FormGroupWrapper>
                    <FormGroupWrapper>
                        <Form.Label>Filter by Draft Type:</Form.Label>
                        <Form.Control
                            as="select"
                            value={draftType}
                            onChange={(e) => setDraftType(e.target.value)}
                        >
                            <option value={"All"}>All</option>
                            <option value={"Full Draft"}>Full Draft</option>
                            <option value={"Weekly Redraft"}>
                                Weekly Redraft
                            </option>
                            <option value={"Team"}>Team</option>
                            <option value={"Weekly Budget"}>
                                Weekly Budget
                            </option>
                            <option value={"Confidence Pool"}>
                                Confidence Pool
                            </option>
                            <option value={"Eliminated Confidence Pool"}>
                                Eliminated Confidence Pool
                            </option>
                        </Form.Control>
                    </FormGroupWrapper>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            <p className="text-center">
                                Showing <b>{leagues.length}</b> of{" "}
                                <b>{total}</b> public leagues
                            </p>
                            <Table>
                                <thead>
                                    <tr className="text-center">
                                        <th>League Name</th>
                                        <th>Draft Type</th>
                                        <th className="d-none d-md-table-cell">
                                            Total Players
                                        </th>
                                        <th className="d-none d-md-table-cell">
                                            Available Spots
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leagues.map((league) => (
                                        <tr
                                            key={league.id}
                                            className="text-center"
                                        >
                                            <td>
                                                {league.name}{" "}
                                                {(league.id === 0 ||
                                                    league.id === "0" ||
                                                    league.id === "team" ||
                                                    league.id === "budget") && (
                                                    <>
                                                        <br />
                                                        <Badge
                                                            pill
                                                            variant="dark"
                                                            style={{
                                                                backgroundColor:
                                                                    "black",
                                                            }}
                                                        >
                                                            RTVF Official
                                                        </Badge>
                                                    </>
                                                )}
                                                {league.sponsored &&
                                                    league.id.toString() !==
                                                        "0" &&
                                                    league.id !== "budget" &&
                                                    league.id !== "team" && (
                                                        <>
                                                            <br />
                                                            <Badge
                                                                pill
                                                                variant="success"
                                                            >
                                                                Sponsored
                                                            </Badge>
                                                        </>
                                                    )}
                                            </td>
                                            <td>{league.draft_type}</td>
                                            <td className="d-none d-md-table-cell">
                                                {league.players}
                                            </td>
                                            <td className="d-none d-md-table-cell">
                                                {league.league_size
                                                    ? league.league_size -
                                                      league.players
                                                    : "Unlimited"}
                                            </td>
                                            <td>
                                                {determineLeagueAction(league)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </>
            )}
        </PageTemplate>
    );
};

export default PublicLeagueSearch;
