import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../components/pages/Home";
import HowItWorks from "../components/pages/HowItWorks";
import AppWrapper from "../components/templates/AppWrapper";
import About from "../components/pages/About";
import LoginModal from "../components/molecules/LoginModal";
import ScrollToTop from "../config/ScrollToTop";
import TradingGuide from "../components/pages/TradingGuide";
import Terms from "../components/pages/Terms";
import Media from "../components/pages/Media";
import Articles from "../components/pages/Articles";
import Show from "../components/pages/Show";
import Article from "../components/pages/Article";
import Scores from "../components/pages/Scores";
import CastScores from "../components/pages/CastScores";
import Myleagues from "../components/pages/Myleagues";
import League from "../components/pages/League";
import LeagueCodeForm from "../components/organisms/LeagueCodeForm";
import JoinLeagueForm from "../components/organisms/JoinLeagueForm";
import ContactUsForm from "../components/pages/ContactUs";
import EditTeamForm from "../components/organisms/EditTeamForm";
import AllShows from "../components/pages/AllShows";
import CreateLeagueForm from "../components/organisms/CreateLeagueForm";
import EditLeagueForm from "../components/organisms/EditLeagueForm";
import AdminRoute from "./AdminRoute";
import AddInstagramBioForm from "../components/organisms/AddInstagramBioForm";
import UserPreferencesForm from "../components/organisms/UserPreferencesForm";
import PrivateRoute from "./PrivateRoute";
import Subscribe from "../components/pages/Subscribe";
import CreateYourOwnShow from "../components/pages/CreateYourOwnShow";
import InstagramBioList from "../components/organisms/InstagramBioList";
import Team from "../components/pages/Team";
import Draft from "../components/pages/Draft";
import PageNotFound from "../components/pages/PageNotFound";
import AdminScoresList from "../components/pages/AdminScoresList";
import AdminArticlesList from "../components/pages/AdminArticlesList";
import AdminArticle from "../components/pages/AdminArticle";
import AdminShowsList from "../components/pages/AdminShowsList";
import AdminShow from "../components/pages/AdminShow";
import AdminLogScores from "../components/pages/AdminLogScores";
import Unsubscribe from "../components/pages/Unsubscribe";
import AddCustomScoring from "../components/pages/AddCustomScoring";
import EditCustomScoring from "../components/pages/EditCustomScoring";
import CustomLogScores from "../components/pages/CustomLogScores";
import CreateCustomShow from "../components/pages/CreateCustomShow";
import EditCustomLeague from "../components/pages/EditCustomLeague";
import CustomLeagueLogScores from "../components/pages/CustomLeagueLogScores";
import PublicLeagueSearch from "../components/pages/PublicLeagueSearch";
import DraftRecap from "../components/pages/DraftRecap";
import Analytics from "../components/pages/Analytics";
import AdminLiveScoresList from "../components/pages/AdminLiveScoresList";
import AdminLogLiveScores from "../components/pages/AdminLogLiveScores";
import LiveScores from "../components/pages/LiveScores";
import SendPushNotification from "../components/pages/SendPushNotification";
import Profile from "../components/pages/Profile";
import EditProfile from "../components/pages/EditProfile";
import News from "../components/pages/News";
import AddCustomChallengeQuestions from "../components/pages/AddCustomChallengeQuestions";
import EditCustomQuestions from "../components/pages/EditCustomQuestions";
import LogCustomAnswers from "../components/pages/LogCustomAnswers";
import DeepLinking from "../components/pages/DeepLinking";

const PublicRouter: React.FC = () => {
    const [showLogin, setShowLogin] = useState(false);

    return (
        <Router>
            <ScrollToTop />
            <AppWrapper showLogin={showLogin} setShowLogin={setShowLogin}>
                <LoginModal
                    show={showLogin}
                    onHide={() => setShowLogin(false)}
                />
                <Switch>
                    <AdminRoute
                        path="/admin/deeplinking"
                        component={DeepLinking}
                    />
                    <AdminRoute
                        path="/admin/sendPushNotification"
                        component={SendPushNotification}
                    />
                    <AdminRoute
                        path="/admin/addinstabio"
                        component={AddInstagramBioForm}
                    />
                    <AdminRoute
                        path="/admin/scores"
                        component={AdminScoresList}
                    />
                    <AdminRoute
                        path="/admin/logscores/:showId/:episodeParam?"
                        component={AdminLogScores}
                    />
                    <AdminRoute
                        path="/admin/articles/:type/:id?"
                        component={AdminArticle}
                    />
                    <AdminRoute
                        path="/admin/articles"
                        component={AdminArticlesList}
                    />
                    <AdminRoute
                        path="/admin/shows/:type/:id?"
                        component={AdminShow}
                    />
                    <AdminRoute
                        path="/admin/shows"
                        component={AdminShowsList}
                    />
                    <AdminRoute
                        path="/admin/liveScores/:showId/:episodeParam?"
                        component={AdminLogLiveScores}
                    />
                    <AdminRoute
                        path="/admin/liveScores"
                        component={AdminLiveScoresList}
                    />
                    <PrivateRoute
                        path="/preferences"
                        exact
                        component={UserPreferencesForm}
                    />
                    <Route path="/subscribe" exact component={Subscribe} />
                    <PrivateRoute
                        path="/build/:type"
                        exact
                        component={CreateCustomShow}
                    />
                    <PrivateRoute
                        path="/build"
                        exact
                        component={CreateYourOwnShow}
                    />
                    <Route
                        path="/custom/scoring/log/:leagueId/:episodeParam?"
                        exact
                        component={CustomLogScores}
                    />
                    <PrivateRoute
                        path="/myleagues/logscores/:showId/:leagueId/:episodeParam?"
                        component={CustomLeagueLogScores}
                    />
                    <PrivateRoute
                        path="/myleagues/loganswers/:leagueId/:episodeParam?"
                        component={LogCustomAnswers}
                    />
                    <Route
                        path="/instabio"
                        exact
                        component={InstagramBioList}
                    />
                    <Route path="/overview" exact component={HowItWorks} />
                    <Route path="/about" exact={true} component={About} />
                    <Route
                        path="/tradingguide"
                        exact
                        component={TradingGuide}
                    />
                    <Route path="/terms" exact component={Terms} />
                    <Route path="/media" exact component={Media} />
                    <Route path="/contact" exact component={ContactUsForm} />
                    <Route
                        path="/unsubscribe/:email"
                        exact
                        component={Unsubscribe}
                    />
                    <PrivateRoute
                        path="/myleagues"
                        exact
                        component={Myleagues}
                    />
                    <PrivateRoute
                        path="/myleagues/join/:id/:showId?"
                        exact
                        component={JoinLeagueForm}
                    />
                    <Route
                        path="/myleagues/join"
                        exact
                        component={LeagueCodeForm}
                    />
                    <Route path="/articles/author/:author_id" exact>
                        <Articles type="author" />
                    </Route>
                    <Route path="/shows/article/:slug/:id" exact>
                        <Article />
                    </Route>
                    <Route path="/shows/article/:slug" exact>
                        <Articles type="show" />
                    </Route>
                    <Route path="/news" exact>
                        <News />
                    </Route>
                    <Route path="/shows" exact component={AllShows} />
                    <PrivateRoute
                        path="/myleagues/search"
                        exact
                        component={PublicLeagueSearch}
                    />
                    <PrivateRoute
                        path="/myleagues/create/:slug?"
                        exact
                        component={CreateLeagueForm}
                    />
                    <Route path="/shows/view/:slug" exact component={Show} />
                    <Route
                        path="/shows/castscores/:cast_id"
                        exact
                        component={CastScores}
                    />
                    <Route
                        path="/shows/scores/:slug/:episode"
                        exact
                        component={Scores}
                    />
                    <Route
                        path="/shows/liveScores/:slug/:episode"
                        exact
                        component={LiveScores}
                    />
                    <PrivateRoute
                        path="/myleagues/view/:id"
                        exact
                        component={League}
                    />
                    <Route
                        path="/myleagues/view/:id/:showId"
                        exact
                        component={League}
                    />
                    <PrivateRoute
                        path="/myleagues/edit/team/:id/:showId"
                        exact
                        component={EditTeamForm}
                    />
                    <PrivateRoute
                        path="/myleagues/edit/league/:id"
                        exact
                        component={EditLeagueForm}
                    />
                    <PrivateRoute
                        path="/myleagues/edit/custom/league/:id"
                        exact
                        component={EditCustomLeague}
                    />
                    <Route
                        path="/custom/scoring/add/:id"
                        exact
                        component={AddCustomScoring}
                    />
                    <Route
                        path="/custom/questions/add/:id"
                        exact
                        component={AddCustomChallengeQuestions}
                    />
                    <PrivateRoute
                        path="/custom/scoring/edit/:id"
                        exact
                        component={EditCustomScoring}
                    />
                    <PrivateRoute
                        path="/custom/questions/edit/:id"
                        exact
                        component={EditCustomQuestions}
                    />
                    <Route
                        path="/myleagues/draft/:id"
                        exact
                        component={Draft}
                    />
                    <Route
                        path="/myleagues/draftRecap/:id"
                        exact
                        component={DraftRecap}
                    />
                    <Route
                        path="/myleagues/analytics/:id/:show_id?"
                        exact
                        component={Analytics}
                    />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/profile/edit" exact component={EditProfile} />
                    <Route path="/myleagues/team/:id" exact component={Team} />
                    <Route path="/" exact component={Home} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
            </AppWrapper>
        </Router>
    );
};

export default PublicRouter;
