import React from "react";

import Form from "react-bootstrap/Form";

import PageTemplate from "../templates/PageTemplate";
import Input from "../atoms/Input";
import SubmitButton from "../atoms/SubmitButton";
import { Formik } from "formik";

const SendPushNotification: React.FC = () => {
    const initialValues = {
        link: "",
    };

    return (
        <PageTemplate header="Deep Linking">
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    window.location.href = values.link;
                }}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Input name="link" label="Link:" placeholder="Link" />
                        <SubmitButton title="Submit" />
                    </Form>
                )}
            </Formik>
        </PageTemplate>
    );
};

export default SendPushNotification;
